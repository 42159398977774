<template>
  <div class="product-features">
    <GlobalTitle :title="section.title" />
    <div class="product-features__line" v-for="(entry, idx) in section.entries" :key="`entry-${idx}`">
      <div class="product-features__line__name">
        {{ entry.name }}
      </div>
      <div class="product-features__line__value">
        {{ entry.value }}
      </div>
    </div>
  </div>
</template>

<script>
import GlobalTitle from '@/components/Global/Title'

export default {
  name: 'ProductFeatures',
  components: {
    GlobalTitle,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.product-features {
  &__line {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0 16px;
    margin-bottom: 8px;

    &__value {
      max-width: 70%;
      margin-left: auto;
      font-weight: 600;
      text-align: right;
    }
  }
}
</style>
