<template>
  <div class="product-traceability">
    <GlobalTitle :title="section.title" />
    <div class="product-traceability__entry" v-for="(entry, idx) in section.entries" :key="`entry-${idx}`">
      <div class="product-traceability__entry__description">
        {{ entry.description }} <a @click="$emit('click')">{{ entry.knowMoreLabel }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalTitle from '@/components/Global/Title'

export default {
  name: 'ProductTraceability',
  components: {
    GlobalTitle,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.product-traceability {
  .global-title {
    color: var(--theme-cta-tertiary-color);
  }

  &__entry {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0 16px;
    margin-bottom: 8px;

    &__description {
      a {
        cursor: pointer;
        text-decoration: underline;
        color: var(--theme-landing-color) !important;
        font-weight: 600;
      }
    }
  }
}
</style>
