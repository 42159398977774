<template>
  <div class="footer tw-py-6 sm:tw-py-8">
    <v-container class="tw-px-4 sm:tw-px-6 tw-py-0">
      <div class="footer__title" v-if="footer.logo">
        <v-img
          :alt="`Footer Image`"
          :class="[$vuetify.rtl ? 'tw-ml-4' : 'tw-mr-4']"
          contain
          :src="footer.logo"
          height="48"
          :position="$vuetify.rtl ? 'center right' : 'center left'"
          max-width="200"
        />
      </div>
      <div class="footer__title" v-else v-html="footer.title"></div>
      <div class="footer__description tw-mt-6">
        {{ footer.description }}
      </div>
      <div class="footer__links tw-mt-6 tw-grid tw-grid-cols-2 tw-gap-4 sm:tw-grid-cols-3 md:tw-grid-cols-4">
        <a :href="link.href" class="footer__links__item" target="_blank" v-for="(link, idx) in footer.links" :key="idx">
          {{ link.label }}
        </a>
        <span class="footer__links__item" @click="setCookieConsent(null)"> {{ $t('label.cookies') }} </span>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Footer',
  props: {
    footer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      setCookieConsent: 'global/setCookieConsent',
    }),
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: var(--theme-footer-background);
  color: var(--theme-footer-color);

  &__title {
    text-transform: uppercase;
  }

  &__description {
    font-size: 0.875rem;
  }

  &__links {
    font-size: 0.75rem;

    &__item {
      cursor: pointer;
      text-decoration: underline;
      color: var(--theme-footer-color) !important;
    }
  }
}
</style>
