<template>
  <div class="product-reviews">
    <GlobalTitle :title="section.title" />
    <div class="product-reviews__comment tw-mt-6 tw-pb-6" v-for="(entry, idx) in section.entries" :key="`entry-${idx}`">
      <div class="product-reviews__comment__header">
        <GlobalStars :rate="entry.grade" size="small" />
        <div class="product-reviews__comment__header__date">
          {{ dateFormatted(entry.date) }}
        </div>
      </div>
      <div
        class="product-reviews__comment__text tw-mt-3"
        :class="{
          'product-reviews__comment__text--too-big': entry.isTooBig && !entry.showFull,
        }"
        :ref="`entry-${idx}`"
      >
        {{ entry.comment }}
      </div>
      <div class="product-reviews__comment__more" @click="toggleComment(entry)">
        <span v-if="!entry.showFull && entry.isTooBig">
          {{ $t('label.readMore') }}
        </span>
        <span v-else-if="entry.isTooBig">
          {{ $t('label.readLess') }}
        </span>
      </div>
      <div class="product-reviews__comment__footer tw-mt-3">
        <div class="product-reviews__comment__footer__user">{{ $t('label.from') }} {{ entry.user }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.util'
import GlobalStars from '@/components/Global/Stars'
import GlobalTitle from '@/components/Global/Title'

export default {
  name: 'ProductReviews',
  components: {
    GlobalStars,
    GlobalTitle,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.section.entries.forEach((entry, i) => {
      this.isTooBig(entry, this.$refs[`entry-${i}`])
    })
  },
  methods: {
    toggleComment(review) {
      review.showFull = !review.showFull
    },
    dateFormatted(date) {
      return formatDate(date, 'DD MMMM YYYY')
    },
    isTooBig(data, target, lines = 3) {
      let itemHeight = 0
      if (Array.isArray(target)) {
        itemHeight = target[0].clientHeight
      } else {
        itemHeight = target.clientHeight
      }
      data.isTooBig = itemHeight > 16 * 1.5 * lines
    },
  },
}
</script>

<style lang="scss" scoped>
.product-reviews {
  &__comment {
    border-bottom: 1px solid #e4e4e4;

    &:last-child {
      border-bottom: 0 !important;
      padding-bottom: 0 !important;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__date {
        text-transform: capitalize;
      }
    }

    &__text {
      &--too-big {
        @include text-ellipsis(3);
      }

      &--show-more {
        max-height: initial;
        -webkit-line-clamp: initial;
      }
    }

    &__more {
      margin-top: 4px;
      cursor: pointer;
      text-decoration: underline;
      font-weight: 600;
    }

    &__footer {
      &__user {
        font-weight: 600;
      }
    }
  }
}
</style>
