<template>
  <div class="product">
    <GlobalIframeDialog
      :dialog="displayTraceability"
      :iframeUrl="getTraceabilityUrl"
      :max-width="700"
      @close="displayTraceabilityDialog(false)"
    />

    <Carousel :items="product.assets" v-if="product.assets && product.assets.length > 0" />
    <v-container
      class="product__container tw-px-4 tw-py-6 md:tw-px-6 sm:tw-py-8"
      :class="{ 'tw--mt-2': product.assets && product.assets.length > 0 }"
    >
      <div class="product__container__top tw-flex tw-flex-col md:tw-flex-row tw-mb-6" v-if="hasProductHeader">
        <div class="product__container__top__rating" v-if="product.grade && product.reviewsCount">
          <GlobalStars :rate="product.grade" />
          <div class="product__container__top__rating__grade" :class="[$vuetify.rtl ? 'tw-mr-3' : 'tw-ml-3']">
            {{ product.grade }}
            <span class="product__container__top__rating__grade__count"
              >({{ product.reviewsCount.toLocaleString() }})</span
            >
          </div>
        </div>
        <v-spacer />
        <div class="product__container__top__traceability md:tw-mt-0 tw-mt-5" v-if="product.traceability">
          <div class="product__container__top__traceability__button">
            <GlobalButton
              :text="product.traceability.button"
              @click="displayTraceabilityDialog(true)"
              assetsPath="traceability.svg"
            />
          </div>
        </div>
      </div>
      <h1 class="product__container__title tw-mt-0 tw-mb-6" v-if="product.name">
        {{ product.name.toUpperCase() }}
      </h1>
      <div
        v-if="product.description"
        class="product__container__description"
        :class="{
          'product__container__description--too-big': isTooBig && !showFull,
        }"
        ref="description"
        v-html="product.description.value"
      ></div>
      <div v-if="product.description" class="product__container__more" @click="toggleDescription">
        <span v-if="!showFull && isTooBig">
          {{ $t('label.readMore') }}
        </span>
        <span v-else-if="isTooBig">
          {{ $t('label.readLess') }}
        </span>
      </div>
      <section
        class="product__container__section tw-mt-12"
        v-for="(section, idx) in product.sections"
        :key="`section-${idx}`"
      >
        <ProductFeatures :section="section" v-if="section.type === 'features'" />
        <ProductTraceability
          :section="section"
          v-if="section.type === 'traceability'"
          @click="displayTraceabilityDialog(true)"
        />
        <ProductReviews :section="section" v-if="section.type === 'reviews'" />
      </section>
    </v-container>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'
import GlobalStars from '@/components/Global/Stars'
import GlobalButton from '@/components/Global/Button'
import GlobalIframeDialog from '@/components/Global/IframeDialog'
import ProductFeatures from '@/components/Product/Features.vue'
import ProductTraceability from '@/components/Product/Traceability.vue'
import ProductReviews from '@/components/Product/Reviews.vue'

export default {
  name: 'Product',
  components: {
    Carousel,
    GlobalStars,
    GlobalButton,
    GlobalIframeDialog,
    ProductFeatures,
    ProductTraceability,
    ProductReviews,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showFull: false,
    isTooBig: false,
    displayTraceability: false,
  }),
  watch: {
    product() {
      this.showFull = false
      this.isTooBig = false
      this.$nextTick(() => {
        this.checkHeight(this.$refs.description)
      })
    },
  },
  mounted() {
    this.checkHeight(this.$refs.description)
  },
  computed: {
    hasProductHeader() {
      return (this.product.grade && this.product.reviewsCount) || this.product.traceability
    },
    getTraceabilityUrl() {
      return this.product.traceability?.url || ''
    },
  },
  methods: {
    toggleDescription() {
      this.showFull = !this.showFull
    },
    displayTraceabilityDialog(displayBoolean) {
      this.displayTraceability = displayBoolean
    },
    checkHeight(target, lines = 3) {
      let itemHeight = 0
      if (Array.isArray(target)) {
        itemHeight = target[0].clientHeight
      } else {
        itemHeight = target.clientHeight
      }
      this.isTooBig = itemHeight > 16 * 1.5 * lines
    },
  },
}
</script>

<style lang="scss" scoped>
.product {
  &__container {
    position: relative;
    z-index: 2;
    border-top-left-radius: var(--theme-global-radius);
    border-top-right-radius: var(--theme-global-radius);
    box-shadow: 0 -2px 6px $color-shadow;
    background-color: var(--theme-landing-background);

    &__top {
      align-items: center;
      justify-content: space-between;

      &__rating {
        display: flex;
        align-items: center;

        &__grade {
          margin-top: 3px;
          line-height: 1;
          font-size: 1.75rem;
          font-weight: bold;

          &__count {
            font-weight: 400;
            font-size: 1.25rem;
          }
        }
      }
    }

    &__title {
      line-height: 1.1;
      color: var(--theme-title-primary-color);
      font-family: var(--theme-title-primary-font), sans-serif;
      font-size: 1.875rem;
    }

    &__description {
      &--too-big {
        @include text-ellipsis(3);
      }

      &--show-more {
        max-height: initial;
        -webkit-line-clamp: initial;
      }
    }

    &__more {
      margin-top: 4px;
      cursor: pointer;
      text-decoration: underline;
      font-weight: 600;
    }
  }
}
</style>
