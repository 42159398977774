<template>
  <v-dialog
    :value="dialog"
    :max-width="maxWidth"
    :persistent="false"
    content-class="global-iframe-dialog"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <div class="global-iframe-dialog__container tw-overflow-hidden">
      <div v-if="loading" class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
        <v-progress-circular color="primary" size="150" width="12" indeterminate></v-progress-circular>
      </div>
      <iframe id="my-frame" :src="iframeUrl" width="100%" v-on:load="toggleLoader"></iframe>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'GlobalIframeDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    iframeUrl: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: [Number, String],
      required: false,
      default: 700,
    },
  },
  data: () => ({
    loading: true,
  }),
  methods: {
    toggleLoader() {
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
.global-iframe-dialog {
  height: 100%;

  &__container {
    height: 100%;

    iframe {
      height: 100%;
    }
  }
}
</style>
