<template>
  <div class="landing" v-if="landing[currentLocale]">
    <template v-if="$vuetify.breakpoint.xsOnly">
      <Product :product="landing[currentLocale].product" v-if="landing.type === 'product'" />
      <div class="landing__starter tw-p-4">
        <div class="landing__starter__title tw-mb-4 tw-text-xl tw-text-center tw-font-medium">
          {{ landing[currentLocale].starter.title }}
        </div>
        <v-btn block large depressed color="primary" class="tw-mt-4" @click.stop="dialogStarter = true">
          {{ landing[currentLocale].starter.cta }}
        </v-btn>
      </div>
    </template>
    <template v-else>
      <v-container class="tw-px-4 tw-py-0 sm:tw-px-6 tw-flex">
        <Product
          :product="landing[currentLocale].product"
          v-if="landing.type === 'product'"
          class="tw-w-3/5 md:tw-w-4/6"
          :class="[$vuetify.rtl ? 'tw-pl-4' : 'tw-pr-4']"
        />
        <div class="tw-w-2/5 md:tw-w-2/6" :class="[$vuetify.rtl ? 'tw-pr-4' : 'tw-pl-4']">
          <div class="landing__game">
            <v-img :src="landing[currentLocale].starter.asset" max-height="450px" position="top center" />
            <div class="landing__starter tw-p-4 sm:tw-p-6">
              <div class="landing__starter__title tw-mb-4 tw-text-xl tw-text-center tw-font-medium">
                {{ landing[currentLocale].starter.title }}
              </div>
              <v-btn block large depressed color="primary" class="tw-mt-4" @click.stop="dialogStarter = true">
                {{ landing[currentLocale].starter.cta }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-container>
    </template>
    <Footer :footer="landing[currentLocale].footer" />
    <Starter
      v-if="landing.type === 'product'"
      :dialog="dialogStarter"
      :theme="theme"
      :starter="landing[currentLocale].starter"
      :loading="loadingStarter"
      @close="dialogStarter = false"
      @play="startBalancer"
    />
    <Balancer
      v-if="availableBalancer"
      :dialog="dialogBalancer"
      :theme="theme"
      :balancer="balancer[currentLocale]"
      :loading="loadingBalancer"
      @close="dialogBalancer = false"
      @play="startGame"
    />
  </div>
</template>

<script>
import Starter from '@/components/Starter.vue'
import Product from '@/components/Product.vue'
import Balancer from '@/components/Balancer.vue'
import Footer from '@/components/Footer.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Landing',
  components: {
    Starter,
    Balancer,
    Product,
    Footer,
  },
  props: {
    theme: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    data: null,
    dialogStarter: false,
    dialogBalancer: false,
    loadingStarter: false,
    loadingBalancer: false,
  }),
  watch: {
    currentLocale() {
      this.generateMetaTitle()
    },
  },
  created() {
    this.generateMetaTitle()
  },
  computed: {
    ...mapState({
      landing: state => state.global.landing,
      balancer: state => state.global.balancer,
      connect: state => state.global.connect,
      currentLocale: state => state.global.currentLocale,
    }),
    getUrl() {
      if (process.env.NODE_ENV === 'development') return 'https://rossi-gi.netlify.app/'
      return window.location.href
    },
    getIsGenericProduct() {
      if (this.landing[this.currentLocale].product?.generic) {
        return this.landing[this.currentLocale].product?.generic
      }
      return false
    },
    getLastUpdateProduct() {
      if (this.landing[this.currentLocale].product?.lastUpdate) {
        return this.landing[this.currentLocale].product?.lastUpdate
      }
      return null
    },
    availableBalancer() {
      return this.balancer?.required && this.balancer[this.currentLocale]
    },
  },
  methods: {
    async startBalancer(values) {
      this.data = {
        ...values.email,
        details: {
          country: values.country,
          state: values.state,
        },
      }
      this.loadingStarter = true
      try {
        await this.getBalancer({
          ...this.data,
          url: this.getUrl,
          locale: this.currentLocale.substring(0, 2),
          generic: this.getIsGenericProduct,
          lastUpdate: this.getLastUpdateProduct,
        })
        if (this.balancer.required) {
          this.dialogBalancer = true
          this.loadingStarter = false
        } else {
          if (this.connect.available) {
            await this.getGame()
            this.$router.push({ name: 'Feedback' })
          } else {
            this.$router.push({ name: 'NoGame' })
          }
          this.loadingStarter = false
        }
      } catch (e) {
        this.$emit('notification')
        this.loadingStarter = false
      }
    },
    async startGame(data) {
      this.loadingBalancer = true
      try {
        await this.getConnect({ ...this.data, balancer: data, url: this.getUrl })
        if (this.connect.available) {
          await this.getGame()
          this.$router.push({ name: 'Feedback' })
        } else {
          this.$router.push({ name: 'NoGame' })
        }
        this.loadingBalancer = false
      } catch (e) {
        this.$emit('notification')
        this.loadingBalancer = false
      }
    },
    ...mapActions({
      getGame: 'global/getGame',
      getConnect: 'global/getConnect',
      getBalancer: 'global/getBalancer',
    }),
    generateMetaTitle() {
      if (this.landing[this.currentLocale]?.meta?.title) {
        document.title = this.landing[this.currentLocale].meta.title
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.landing {
  height: 100%;
  color: var(--theme-landing-color);

  &__game {
    height: 100%;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      position: sticky;
      top: 56px;
      height: initial;
    }
  }

  &__starter {
    position: sticky;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    box-shadow: 0 -2px 6px $color-shadow;
    background-color: var(--theme-starter-background);
    color: var(--theme-starter-color);

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      position: sticky;
    }

    &__subtitle {
      text-transform: uppercase;
    }

    &__description {
      font-size: 0.875rem;
    }
  }
}
</style>
