<template>
  <div class="global-stars" :class="`global-stars--${size}`">
    <div class="global-stars__wrapper">
      <div
        class="global-stars__wrapper__rate"
        :style="{
          width: convertedRate + '%',
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="94"
          height="80.008"
          viewBox="0 0 94 80.008"
          v-for="n in 5"
          :key="n"
        >
          <g transform="translate(-226 -1349.995)">
            <rect width="94" height="80" transform="translate(226 1350)" fill="none" />
            <path
              d="M222.643,35.336l10.945,25.251,28,2.353a2.139,2.139,0,0,1,1.259,3.766L241.6,84.661l6.364,26.708a2.192,2.192,0,0,1-3.284,2.33l-24.066-14.16L196.545,113.7a2.194,2.194,0,0,1-3.284-2.33l6.364-26.708L178.379,66.705a2.141,2.141,0,0,1,1.259-3.773l28-2.345,10.937-25.251a2.224,2.224,0,0,1,4.068,0Z"
              transform="translate(52.388 1315.984)"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalStars',
  props: {
    rate: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  computed: {
    convertedRate() {
      return ((this.rate / 5) * 100).toString()
    },
  },
}
</script>

<style lang="scss" scoped>
.global-stars {
  &--small {
    width: 120px;
    height: 20px;
  }

  &--medium {
    width: 160px;
    height: 28px;
  }

  &__wrapper {
    background: url('~@/assets/images/star-empty.svg') left repeat-x;
    width: 100%;
    height: 100%;

    .global-stars--small & {
      background-size: 24px 100%;
    }

    .global-stars--medium & {
      background-size: 32px 100%;
    }

    &__rate {
      transition: width 1s ease;
      width: 0;
      height: 100%;
      overflow: hidden;
      white-space: nowrap;
      will-change: width;

      > svg {
        fill: var(--theme-review-color);

        .global-stars--small & {
          width: 24px;
          height: auto;
        }

        .global-stars--medium & {
          width: 32px;
          height: auto;
        }
      }

      .global-stars--small & {
        background-size: 24px 100%;
      }

      .global-stars--medium & {
        background-size: 32px 100%;
      }
    }
  }
}
</style>
