<template>
  <v-btn rounded outlined class="global-button" @click="$emit('click')">
    <v-img
      class="global-button__icon"
      v-if="assetsPath"
      :src="require(`@/assets/images/${assetsPath}`)"
      max-width="75%"
    />
    <span class="global-button__text">{{ text }}</span>
  </v-btn>
</template>

<script>
export default {
  name: 'GlobalButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    assetsPath: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.global-button {
  position: relative;
  color: var(--theme-cta-tertiary-color) !important;
  font-family: var(--theme-title-secondary-font), sans-serif;

  &__icon {
    margin-top: 2px;
    width: 30px;
  }
  &__text {
    padding-left: 5px;
    font-size: 0.75rem;
  }
}
</style>
