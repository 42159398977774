<template>
  <v-dialog :value="dialog" content-class="starter-product tw-m-4" @click:outside="$emit('close')" max-width="450">
    <v-card class="starter-product__card tw-p-4 sm:tw-p-6">
      <div class="tw-text-lg tw-pr-8">
        {{ starter.modalTitle }}
      </div>
      <v-btn icon :dark="isDark" class="starter-product__close" @click="$emit('close')">
        <v-icon dark> mdi-close </v-icon>
      </v-btn>

      <div class="tw-mt-6">
        <template v-for="(field, idx) in starter.fields">
          <IdentityEmail
            v-if="field.key === 'email'"
            :key="`starter-field-${idx}`"
            class="tw-mt-2"
            :field="field"
            :value="values.email"
            :validating="validating"
            @reset="onReset(field.key)"
            @change="setStarter($event, field.key)"
          />
          <IdentityBirthday
            v-if="field.key === 'birthday'"
            :key="`starter-field-${idx}`"
            :field="field"
            :value="values.birthday"
            :validating="validating"
            @change="onInput($event, field.key)"
          />
          <IdentityCountry
            v-if="field.key === 'country'"
            :key="`starter-field-${idx}`"
            class="tw-mt-2"
            :user-country="userCountry"
            :countries="countries[currentLocale]"
            :field="field"
            :value="values.country || userCountry"
            :validating="validating"
            @change="setStarter($event, field.key)"
          />
          <IdentityState
            v-if="field.key === 'state' && (values.country === 'US' || values.country === 'CA')"
            :key="`starter-field-${idx}`"
            class="tw-mt-2"
            :states="states[values.country]"
            :field="field"
            :value="values.state"
            :country="values.country"
            :validating="validating"
            @change="setStarter($event, field.key)"
          />
          <IdentityRules
            v-if="field.key === 'rules' || field.key === 'gdpr'"
            :key="`starter-field-${idx}`"
            class="tw-mt-2"
            :field="field"
            :value="values[field.key]"
            :is-dark="isDark"
            :validating="validating"
            @change="setStarter($event, field.key)"
          />
        </template>
      </div>
      <v-btn
        :dark="isDark"
        block
        large
        depressed
        color="primary"
        class="tw-mt-2"
        @click="onSubmit"
        :disabled="loading"
        :loading="loading"
      >
        {{ starter.cta }}
      </v-btn>
      <div class="tw-mt-6 tw-flex tw-items-center">
        <img
          :src="require('@/assets/images/privacy.svg')"
          :class="[$vuetify.rtl ? 'tw-ml-4' : 'tw-mr-4']"
          width="24px"
        />
        <div class="tw-text-xs" v-html="$t('label.legalsMentions')"></div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { empty } from '@/utils/object.util'
import { countries } from '@/config/countries.config'
import { states } from '@/config/states.config'
import IdentityEmail from '@/components/Identity/Email.vue'
import IdentityBirthday from '@/components/Identity/Birthday.vue'
import IdentityCountry from '@/components/Identity/Country.vue'
import IdentityState from '@/components/Identity/State.vue'
import IdentityRules from '@/components/Identity/Rules.vue'

export default {
  name: 'Starter',
  components: {
    IdentityEmail,
    IdentityBirthday,
    IdentityCountry,
    IdentityState,
    IdentityRules,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    theme: {
      type: Object,
      required: true,
    },
    starter: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    values: {},
    countries,
    states,
    validating: false,
  }),
  computed: {
    ...mapState({
      currentLocale: state => state.global.currentLocale,
      userCountry: state => state.global.user.country,
    }),
    isDark() {
      return this.theme['starter'].dark
    },
    isNotValid() {
      const requiredFields = this.starter.fields.filter(field => field.required)
      return requiredFields.some(field => {
        return (
          empty(this.values[field.key]) ||
          ((this.values.country === 'US' || this.values.country === 'CA') && empty(this.values.state))
        )
      })
    },
  },
  methods: {
    onSubmit() {
      this.validating = true
      if (!this.isNotValid) {
        this.validating = false
        this.$emit('play', this.values)
      }
    },
    onReset(key) {
      delete this.values[key]
      this.values = {
        ...this.values,
      }
    },
    setStarter(e, key) {
      this.values = {
        ...this.values,
        [key]: typeof e === 'string' ? e.trim() : e,
      }
    },
  },
}
</script>

<style lang="scss">
.starter-product {
  &__card {
    background-color: var(--theme-starter-background) !important;
    color: var(--theme-starter-color) !important;

    .v-input--checkbox {
      .v-label {
        font-size: 0.875rem;
      }
    }
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 6px;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      top: 20px;
      right: 14px;
    }
  }
}
</style>
