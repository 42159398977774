<template>
  <v-dialog :value="dialog" content-class="balancer tw-m-4" @click:outside="$emit('close')" max-width="450">
    <v-card class="balancer__card tw-p-4 sm:tw-p-6">
      <div class="tw-text-lg tw-pr-8">
        {{ balancer.title }}
      </div>
      <v-btn icon :dark="isDark" class="balancer__close" @click="$emit('close')">
        <v-icon dark> mdi-close </v-icon>
      </v-btn>
      <div
        class="balancer__card__question"
        :class="[idx === 0 ? 'tw-mt-6' : 'tw-mt-8']"
        v-for="(question, idx) in balancer.questions"
        :key="`question-${idx}`"
      >
        <div class="balancer__card__question__title tw-text-lg tw-font-semibold">
          {{ computedQuestion(question) }}
        </div>
        <div class="balancer__card__question__answer">
          <div class="balancer__card__question__answer__description tw-mt-2" v-if="question.description">
            {{ question.description }}
          </div>
          <InputRadio
            v-if="question.type === 'radio'"
            :value="values[question.id]"
            :question="question"
            :is-dark="isDark"
            :validating="validating"
            @change="setBalancer"
          />
          <InputCheckbox
            v-if="question.type === 'checkbox'"
            :value="values[question.id]"
            :question="question"
            :is-dark="isDark"
            :validating="validating"
            @change="setBalancer"
          />
          <InputTextarea
            v-if="question.type === 'textarea'"
            class="tw-mt-2"
            :value="values[question.id]"
            :question="question"
            :validating="validating"
            @change="setBalancer"
          />
          <InputTextfield
            v-if="question.type === 'textfield'"
            class="tw-mt-2"
            :value="values[question.id]"
            :question="question"
            :validating="validating"
            @change="setBalancer"
          />
          <InputDatePicker
            v-if="question.type === 'datepicker'"
            class="tw-mt-2"
            :value="values[question.id]"
            :question="question"
            :validating="validating"
            @change="setBalancer"
          />
          <InputDropdown
            v-if="question.type === 'dropdown'"
            class="tw-mt-2"
            :value="values[question.id]"
            :question="question"
            :validating="validating"
            @change="setBalancer"
          />
          <InputReview
            v-if="question.type === 'review'"
            class="tw-mt-2"
            :value="values[question.id]"
            :question="question"
            :validating="validating"
            @change="setBalancer"
          />
        </div>
      </div>
      <v-btn
        :dark="isDark"
        block
        large
        depressed
        color="primary"
        class="tw-mt-2"
        @click="onSubmit"
        :disabled="loading"
        :loading="loading"
      >
        {{ balancer.cta }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { empty } from '@/utils/object.util'
import InputRadio from '@/components/Input/Radio.vue'
import InputCheckbox from '@/components/Input/Checkbox.vue'
import InputTextarea from '@/components/Input/Textarea.vue'
import InputTextfield from '@/components/Input/Textfield.vue'
import InputDatePicker from '@/components/Input/DatePicker.vue'
import InputDropdown from '@/components/Input/Dropdown.vue'
import InputReview from '@/components/Input/Review.vue'

export default {
  name: 'Starter',
  components: {
    InputRadio,
    InputCheckbox,
    InputTextarea,
    InputTextfield,
    InputDatePicker,
    InputDropdown,
    InputReview,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    theme: {
      type: Object,
      required: true,
    },
    balancer: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    values: {},
    validating: false,
  }),
  computed: {
    isDark() {
      return this.theme.game.dark
    },
    isNotValid() {
      const requiredQuestions = this.balancer.questions.filter(question => question.required)
      return requiredQuestions.some(question => empty(this.values[question.id]))
    },
  },
  methods: {
    onSubmit() {
      this.validating = true
      if (!this.isNotValid) {
        this.validating = false
        this.$emit('play', this.values)
      }
    },
    computedQuestion(question) {
      if (question.required) {
        return `${question.title} *`
      }
      return question.title
    },
    setBalancer(payload) {
      this.values = {
        ...this.values,
        [payload.id]: typeof payload.value === 'string' ? payload.value.trim() : payload.value,
      }
    },
  },
}
</script>

<style lang="scss">
.balancer {
  &__card {
    background-color: var(--theme-starter-background) !important;
    color: var(--theme-starter-color) !important;

    .v-input--checkbox {
      .v-label {
        font-size: 0.875rem;
      }
    }
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 6px;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      top: 20px;
      right: 14px;
    }
  }
}
</style>
