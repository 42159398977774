<template>
  <h2 class="global-title tw-mb-6 tw-leading-6">
    {{ title }}
  </h2>
</template>

<script>
export default {
  name: 'GlobalTItle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.global-title {
  position: relative;
  text-transform: uppercase;
  color: var(--theme-title-secondary-color);
  font-family: var(--theme-title-secondary-font), sans-serif;
  font-size: 1.375rem;
  font-weight: bold;
}
</style>
