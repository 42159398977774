<template>
  <swiper ref="carousel" :options="swiperOptions" @ready="checkPagination" class="carousel">
    <v-btn
      v-show="hasPagination"
      icon
      large
      color="primary"
      slot="button-prev"
      class="carousel__prev swiper-button-prev"
    >
      <v-icon> mdi-chevron-left </v-icon>
    </v-btn>
    <v-btn
      v-show="hasPagination"
      icon
      large
      color="primary"
      slot="button-next"
      class="carousel__next swiper-button-next"
    >
      <v-icon> mdi-chevron-right </v-icon>
    </v-btn>

    <swiper-slide v-for="(item, i) in items" :key="i" class="carousel__slide">
      <img :src="item" class="carousel__slide__item" />
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'Carousel',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      defaultOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
          nextEl: `.swiper-button-next`,
          prevEl: `.swiper-button-prev`,
        },
        autoplay: false,
      },
      hasPagination: false,
    }
  },
  mounted() {
    this.swiper.on('paginationUpdate', this.checkPagination)
  },
  computed: {
    swiperOptions() {
      return Object.assign({}, this.defaultOptions, this.options)
    },
    swiper() {
      return this.$refs.carousel && this.$refs.carousel.$swiper
    },
  },
  methods: {
    checkPagination() {
      this.hasPagination = this.items.length > 1
      this.swiper.allowTouchMove = this.hasPagination
    },
  },
}
</script>

<style lang="scss">
$carousel-pagination-size: 16px;

.carousel {
  .swiper-wrapper {
    align-items: center;
  }

  &__prev,
  &__next {
    position: absolute;
    transform: translateY(-50%);
    margin: initial;

    &:after {
      display: none;
    }
  }

  &__slide {
    display: inline-flex;
    justify-content: center;

    &__item {
      max-width: 100%;
      max-height: 400px;
    }
  }
}
</style>
